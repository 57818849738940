body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../../../../public/images/employee_background.jpg);
}

#employee {
  width: 100%;
  height: 61rem;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media only screen and (max-width: 414px) and (min-width: 320px) {
  #employee {
    padding-top: 10rem;
  }
}

#employee .employee-content {
  position: relative;
  top: 2.5rem;
  color: var(--light);
}

@media only screen and (max-width: 700px) and (min-width: 320px) {
  #employee .employee-content {
    top: 3rem;
  }
}

@media only screen and (max-width: 505px) and (min-width: 320px) {
  #employee .employee-content .toTeam {
    display: none;
  }
}

#employee .employee-content .toTeam {
  position: absolute;
  font-size: 5rem;
  top: -1rem;
  left: 0rem;
  cursor: pointer !important;
}

#employee .employee-content .toTeam .bx {
  color: var(--light);
}

#employee .employee-content .toTeam .bx:hover {
  scale: 1.2;
}

#employee .employee-content .container .imgteam {
  position: relative;
  display: flex !important;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 10px solid rgba(217, 213, 213, 0.25);
  transition: ease-out 0.5s;
  margin-bottom: 3rem;
  overflow: hidden;
}

#employee .employee-content .container .imgteam img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}

#employee .employee-content .container .picture {
  display: flex;
  justify-content: center;
  align-items: center;
}

#employee .employee-content .container h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 0.4rem;
}

#employee .employee-content .container h3 {
  text-align: center;
  padding-bottom: 2rem;
  color: var(--primary);
}

#employee .employee-content .container p {
  text-align: center;
  padding-top: 2rem;
  font-size: 1.3rem;
}

#employee .employee-content .container .soc {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

#employee .employee-content .container .soc i {
  font-size: 4rem;
}

#employee .employee-content .container .soc a {
  color: var(--light);
}
