.app {
  background-color: rgb(27, 27, 27);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: row-reverse;
}

.app .loadersvg {
  height: 90px;
  padding-left: 1.5rem;
}

.app .loadermain {
  height: 80px;
  padding-right: 2rem;
  margin-top: 1rem;
  overflow-x: hidden !important;
}
