.Contact-page {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../../../../public/images/employee_background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.Contact {
  margin-top: 10rem;
}

.Contact .col-md-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardc {
  display: flex;
  background: #222222ad;
  width: 60%;
  height: 65%;
  margin-top: 40px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3), -1px -1px 8px rgba(0, 0, 0, 0.3);
  text-transform: none;
  backdrop-filter: blur(10px);
  margin-bottom: 8.5rem;
}

.card-text {
  margin: 25px;
  white-space: nowrap;
  width: 100%;
}
.contactdeck {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 8.5rem;
}

.cardc .bx-info-square {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  color: var(--primary);
}
.cardc a {
  color: #ffffff;
}

.cardc h2 {
  color: white;
  padding-bottom: 20px;
}

.cardc p {
  color: white;
  vertical-align: middle;
  font-weight: 500;
  line-height: 1.8rem;
  padding-bottom: 1rem;
}

.cardc .center {
  display: flex !important;
  text-align: center !important;
  justify-content: unset !important;
}

.cardc .centernavig {
  display: flex !important;
  text-align: center !important;
  justify-content: unset !important;
}

.rezervationc {
  color: rgb(184, 53, 53) !important;
  font-weight: 700 !important;
}

.reserve {
  margin-top: 2rem;
}

.Contact .card-text img {
  width: 8%;
  padding-bottom: 1rem;
}

.navigation {
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
}

.navigation h3 {
  color: var(--primary);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  padding-right: 0.5rem;
}

.navigation .bxs-map-pin {
  color: var(--primary);
}

.navigation .bxs-map-pin:hover {
  scale: unset !important;
}

.Contact .card-text .bxl-instagram,
.Contact .card-text .bxs-phone {
  font-size: 1.8rem;
  color: var(--light);
  padding-right: 0.5rem;
}

.Contact .card-text .bx:hover {
  scale: unset;
}

.Contact .map {
  opacity: 0.8;
  position: relative;
  height: 1px;
  bottom: 1rem;
}

.Contact .map img {
  width: 100%;
}

@media only screen and (max-width: 935px) and (min-width: 770px) {
  .Contact .col-md-6 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 615px) {
  .Contact .cardc {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1023px) {
  .Contact .map img {
    display: none;
  }
}

.Contact-page .footer {
  position: relative;
  display: flex;
  bottom: 3rem;
  left: 0;
  z-index: 999;
  color: var(--light);
  justify-content: center;
}

@media only screen and (max-width: 1023px) {
  .Contact-page .footer {
    bottom: -1rem !important;
    text-align: center;
    padding: 0 1rem;
  }
}
