.Pricelist-page {
  background-image: linear-gradient(rgba(5, 5, 5, 0.6), rgba(5, 5, 5, 0.6)), url(../../../../public/images/contact-background.png);
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  overflow-x: hidden !important;
}

.Pricelist .container {
  padding-top: 7rem;
}

.Pricelist .Pricelist-card {
  position: relative;
  width: 100%;
  height: 230px;
  padding-top: 2rem;
  cursor: pointer;
  overflow: hidden;
}
@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .Pricelist .col-md-3 {
    width: 50% !important;
  }
}

.Pricelist .Pricelist-card:hover > .Pricelist-card-front {
  transform: perspective(600px) rotateY(-180deg);
}

.Pricelist .Pricelist-card:hover > .Pricelist-card-back {
  transform: perspective(600px) rotateY(0deg);
}

.Pricelist .Pricelist-card .Pricelist-card-front,
.Pricelist .Pricelist-card .Pricelist-card-back {
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.4s linear;
}

.Pricelist .Pricelist-card .Pricelist-card-back {
  background-color: rgba(30, 30, 30, 0.77);
  transform: perspective(600px) rotateY(180deg);
}

.Pricelist .Pricelist-card .Pricelist-card-front {
  background-color: rgba(30, 30, 30, 0.77);
  transform: perspective(600px) rotateY(0);
}

.Pricelist .Pricelist-card .Pricelist-card-front::after {
  border: solid 1px var(--primary);
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 43px;
  left: 10px;
  content: "";
}

.Pricelist .Pricelist-card .Pricelist-card-back .Pricelist-back-content {
  text-align: center;
  width: 100%;
}

.Pricelist .Pricelist-card .Pricelist-card-front .Pricelist-front-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80%;
  text-align: center;
}

.Pricelist .Pricelist-card .Pricelist-card-front .Pricelist-front-content h1 {
  font-size: 1rem;
  font-weight: 300;
  padding-bottom: 1rem;
  color: var(--light);
  text-transform: uppercase;
  line-height: 1.5rem;
  padding: 0rem 1.1rem;
}

.Pricelist .Pricelist-card .Pricelist-card-front .Pricelist-front-content h3 {
  font-size: 2.2rem;
  color: var(--primary);
}

.Pricelist .Pricelist-card .Pricelist-card-front .Pricelist-front-content img {
  width: 8%;
  margin-bottom: 1rem;
  margin-top: 0.6rem;
}

.Pricelist .Pricelist-card .Pricelist-card-back .Pricelist-back-content img {
  width: 8%;
  margin-top: 1.5rem;
  padding-bottom: 2rem;
}

.Pricelist .Pricelist-card .btn-reservation {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  padding: 0.8rem 2rem;
  border: solid 2px var(--primary);
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1rem;
  transition: 0.2s ease-in;
}

@media only screen and (max-width: 1200px) and (min-width: 320px) {
  .Pricelist .Pricelist-card .btn-reservation {
    top: 55% !important;
  }
}

.btn-reservation:hover {
  background-color: var(--primary);
}

#Opening-hours {
  margin-top: 2rem;
}

#Opening-hours .business-hours {
  width: 60%;
  background-color: rgba(30, 30, 30, 0.77);
  padding: 25px 14px;
  margin-top: -16px;
  padding-top: 3rem;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2rem;
}

#Opening-hours .center-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Opening-hours .business-hours .title {
  font-size: 20px;
  color: var(--light);
  text-transform: uppercase;
  padding-left: 5px;
  border-left: 4px solid #ffac0c;
}

#Opening-hours .business-hours li {
  color: var(--light);
  line-height: 35px;
  border-bottom: 1px solid rgba(249, 176, 51, 0.081);
  padding-left: 0.7rem;
  font-size: 1.1rem;
}

#Opening-hours .business-hours li:first-child {
  padding-top: 0.6rem;
}

#Opening-hours .business-hours li:last-child {
  border-bottom: none;
}

#Opening-hours .business-hours .opening-hours li.today {
  color: #ffac0c;
  font-size: 1.4rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) and (min-width: 320px) {
  .Pricelist .container .col-md-3 {
    width: 100% !important;
  }

  .Pricelist .Pricelist-card .Pricelist-card-back .Pricelist-back-content img,
  .Pricelist .Pricelist-card .Pricelist-card-front .Pricelist-front-content img {
    width: 4%;
  }

  #Opening-hours .business-hours {
    width: 100% !important;
    margin-bottom: 2rem;
  }

  .Contact .col-md-6 {
    margin-bottom: -6rem !important;
  }

  .Contact {
    margin-top: 8rem !important;
  }
}
