:root {
  --primary: rgb(249, 178, 51);
  --light: rgb(255, 255, 255);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Signika", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  list-style: none;
  text-decoration: none;
}

/* Team */
#Team {
  width: 100%;
  margin: 0;
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../../public/images/sec2back.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.containerteam {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4rem;
}

.team_whole {
  padding: 5rem 0;
}

.containerteam .cardteam {
  position: relative;
  height: 340px;
  background-color: rgba(30, 30, 30, 0.757);
  margin: 15px;
  box-shadow: 0 15px 15px rgb(0 0 0 / 50%);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
}
@media only screen and (max-width: 1200px) and (min-width: 465px) {
  .containerteam .col-md-4 {
    width: 50%;
  }
}

@media only screen and (max-width: 770px) and (min-width: 465px) {
  .containerteam .col-md-4 {
    width: 100%;
  }
}

.containerteam .cardteam .contentteam {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.5;
  transition: 0.3s;
}

.containerteam .cardteam:hover .contentteam {
  opacity: 1;
  transform: translateY(-20px);
}

.containerteam .cardteam .contentteam .imgteam {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 10px solid rgba(0, 0, 0, 0.25);
  overflow: hidden;
  filter: grayscale(100%);
}

.containerteam .cardteam .contentteam .imgteam img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.containerteam .cardteam .contentteam .contentBteam h3 {
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin: 20px 0 10px;
  line-height: 1.1em;
  padding: 0 1rem;
}

.containerteam .cardteam .contentteam .contentBteam h3 span {
  font-size: 14px;
  font-weight: 300;
  text-transform: initial;
  color: var(--primary);
  margin-top: 0.5rem;
}

.containerteam .cardteam .sci {
  position: absolute;
  bottom: 10px;
  display: flex;
  background: transparent !important;
}

.containerteam .cardteam .sci li {
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.1s * var(--i));
}
.containerteam .cardteam:hover .sci li {
  transform: translateY(0px);
  opacity: 1;
}

.bx {
  transition: 0.2s;
}

.bx:hover {
  scale: 1.2;
}

.containerteam .cardteam .sci li a {
  color: white;
  font-size: 24px;
}

.containerteam .more-info {
  position: absolute;
  right: -1rem;
}

.containerteam .more-info li {
  margin: 0 10px;
  transform: translateX(0px);
  transition: 0.5s;
  opacity: 0.2;
  transition-delay: calc(0.1s * var(--i));
}

@media only screen and (max-width: 1400px) and (min-width: 465px) {
  .containerteam .more-info {
    right: -1rem !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 465px) {
  .containerteam .more-info {
    right: -1rem !important;
  }
  .containerteam .more-info a {
    font-size: 4rem !important;
  }
}

@media only screen and (max-width: 465px) and (min-width: 320px) {
  .containerteam .more-info {
    right: -1rem !important;
  }

  .containerteam .cardteam .sci li a {
    display: none;
  }

  .containerteam .cardteam .contentteam .imgteam {
    margin-top: 2rem;
  }

  .containerteam .more-info a {
    font-size: 5rem !important;
  }
}

@media only screen and (max-width: 397px) and (min-width: 320px) {
  .containerteam .more-info {
    right: -0.5rem !important;
    top: 8rem;
  }

  .containerteam .more-info a {
    font-size: 4rem !important;
  }
}

@media only screen and (max-width: 370px) and (min-width: 320px) {
  .containerteam .more-info {
    right: -1rem !important;
    top: 6.7rem;
  }

  .containerteam .more-info a {
    font-size: 4rem !important;
  }
}

@media only screen and (min-width: 1413px) {
  #Team {
    overflow-y: hidden !important;
  }
}

.containerteam .cardteam:hover .more-info li {
  transform: translateX(-40px);
  transform: translateY(-20px);
  opacity: 1;
}

.containerteam .more-info a {
  color: white;
  font-size: 6rem;
}

/* Team */

/* SCROLLBAR */

*::-webkit-scrollbar {
  width: 0.2rem;
}

*::-webkit-scrollbar-track {
  background: rgb(27, 27, 27);
}

*::-webkit-scrollbar-thumb {
  background: rgba(249, 176, 51, 0.233);
}

/* SCROLLBAR */

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
