:root {
  --primary: rgb(249, 178, 51);
  --light: rgb(255, 255, 255);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Signika", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  list-style: none;
  text-decoration: none;
}

/* NAVBAR */

nav {
  background: rgba(25, 24, 24, 0.521);
  height: 120px;
  width: 100%;
  padding: 10px 25px;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99;
}

nav ul {
  float: none;
  text-align: center;
}

nav ul li {
  display: inline-block;
  line-height: 100px;
  margin: 0 5px;
}

nav ul li a {
  color: var(--light);
  font-size: 16px;
  padding: 7px 10px;
  cursor: pointer;
  font-weight: 400;
  text-transform: uppercase;
}

nav ul li a:hover {
  border-left: 2px solid var(--primary);
  border-right: 2px solid var(--primary);
  color: rgb(255, 255, 255);
}

.logo {
  margin-top: 0.7rem;
  height: auto;
  z-index: 999;
  position: absolute;
  left: 2rem;
  width: 6%;
}

@media (max-width: 1765px) {
  .logo {
    width: 8% !important;
    margin-top: 0.2rem;
  }
}

@media (max-width: 1520px) {
  .logo {
    width: 10% !important;
    margin-top: 0.2rem;
  }
}

@media (max-width: 1520px) {
  .logo {
    width: 10% !important;
    margin-top: 0.8rem;
  }
  nav ul li a {
    font-size: 14px !important;
  }
}

.checkbtn {
  display: none;
}

#check {
  display: none;
}

.menu {
  display: flex;
  justify-content: center;
}

.menu-button {
  display: flex;
  padding: 5px;
  margin: 2px;
  transition: 200ms;
  color: white;
}

.menu-button i {
  fill: var(--light) !important;
  height: 20px !important;
  -webkit-transform: scale(3);
  transform: scale(3);
  width: 20px !important;
  position: absolute;
  right: 2rem;
  top: 3.2rem;
}

@media (max-width: 952px) {
  label.logo {
    font-size: 30px;
    padding-left: 50px;
  }

  nav ul li a {
    font-size: 16px;
  }
}

@media (max-width: 1190px) {
  .mobile-dropdown {
    display: unset !important;
    width: 100%;
    visibility: visible !important;
  }

  .checkbtn {
    font-size: 50px;
    color: white;
    float: right;
    line-height: 100px;
    margin-right: 40px;
    cursor: pointer;
  }

  .logo {
    width: 100px !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }

  .checkbtn {
    display: block;
  }

  nav ul {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgb(23 23 23 / 99%);
    top: 120px;
    text-align: center;
    transition: all 0.5s;
    left: 0;
  }

  nav ul li {
    display: block;
    margin: 40px 0;
    line-height: 30px;
  }

  nav ul li a {
    font-size: 20px;
  }

  .active {
    background: none;
  }

  #check:checked ~ ul {
    left: 0;
  }

  .dropdown {
    visibility: hidden;
    right: 0 !important;
    top: 29rem !important;
  }

  #myDIV {
    display: none;
  }

  .mobile-navbar {
    display: inline-block !important;
  }

  .menu {
    align-items: center;
    padding: 0.5rem 5rem;
  }

  nav ul li a {
    text-transform: unset !important;
  }

  .btnrezervation {
    padding: 8px 50px !important;
    display: inline-block !important;
  }
}

@media (max-width: 600px) {
  .menu {
    padding: 0.5rem 2rem;
  }
}

.dropdown {
  position: absolute;
  right: 2rem;
  color: white;
  margin: 5px;
  top: 1.05rem;
}

.dropdown .czech_a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 8px 5px;
}

.dropdown .czech_img {
  width: 35px;
  margin-right: 15px;
}

.dropdown .en_a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 8px 5px;
}

.dropdown .en_img {
  width: 35px;
  margin-right: 15px;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 9999;
  box-shadow: 0px 8px 16px 0px rgba(27, 27, 27, 0.959);
  background-color: #fff;
  color: #000;
  height: 80px;
  width: 150px;
  padding: 4px;
}

.dropdown:hover .dropdown-content {
  display: flex;
  align-items: center;
  background: rgb(27, 27, 27);
}
.btnrezervation {
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  padding: 6px 15px;
  background: linear-gradient(-45deg, rgb(216, 151, 40), #8a5f03, #b97d0e, rgb(223, 158, 47));
  background-size: 400% 400%;
  position: relative;
  animation: change 5s ease-in-out infinite;
  border-radius: 3px;
}

.btnrezervation:hover {
  border: none;
}

/* NAVBAR */

/* SECTION */

#Home {
  height: auto;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  background-color: rgba(25, 24, 24, 0.521);
  overflow: hidden !important;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#Home .background {
  background-image: url(../../../../public/images/barberlogo.svg);
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: scroll;
  overflow: hidden;
  position: absolute;
  background-size: 80vh;
  z-index: 2;
}

#Home .btn a {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 30px;
  background: linear-gradient(-45deg, rgb(216, 151, 40), #8a5f03, #5c3d03, rgb(223, 158, 47));
  letter-spacing: 1px;
  border-radius: 4px;
  text-align: center;
  width: 250px;
  background-size: 400% 400%;
  animation: change 5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-weight: 400;
  z-index: 2;
}

@media only screen and (max-width: 860px) and (min-width: 320px) {
  #Home .btn {
    top: 78% !important;
  }

  .socialicons {
    left: 50% !important;
    top: 90% !important;
  }
}

@media only screen and (max-width: 520px) and (min-width: 320px) {
  #Home .btn {
    top: 65% !important;
  }
}

#Home .btn:hover {
  transition: 600ms;
}

#Price_list {
  height: auto;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  background: rgb(27, 27, 27);
  overflow-x: scroll;
  overflow: hidden;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(./img/sec2back.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media only screen and (max-width: 2000px) and (min-width: 1755px) {
  #Price_list {
    height: 100vh;
  }
}

#Price_list .titlebox {
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}
#Price_list .titlebox h2 {
  font-size: 35px;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  position: relative;
  margin-left: auto;
  margin-right: 15vh;
  font-family: "Teko", sans-serif;
  letter-spacing: 5px;
  font-size: 50px;
}

#Price_list .titlebox h2:before {
  position: absolute;
  bottom: 8px;
  content: "";
  width: 300px;
  margin-left: -150px;
  height: 2px;
  left: 50%;
  background: #5d0ae2;
}

#Contact {
  height: auto;
  width: 100%;
  margin: 0;
  background: rgb(27, 27, 27);
  overflow-x: scroll;
  overflow: hidden;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(./img/sec3back.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#Contact .titlebox {
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}
#Contact .titlebox h2 {
  font-size: 35px;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  font-family: "Teko", sans-serif;
  letter-spacing: 5px;
  font-size: 50px;
}

@media only screen and (max-width: 1225px) and (min-width: 320px) {
  #Contact .titlebox h2 {
    margin-top: 20%;
  }
}

@media only screen and (max-width: 610px) and (min-width: 320px) {
  #Contact .titlebox h2 {
    margin-top: 25%;
  }
}

@media only screen and (max-width: 610px) and (min-width: 320px) {
  #Contact .titlebox h2 {
    margin-top: 30%;
  }
}

@media only screen and (max-width: 440px) and (min-width: 320px) {
  #Contact .titlebox h2 {
    margin-top: 40%;
  }
}

#Contact .titlebox h2:before {
  position: absolute;
  bottom: 5px;
  content: "";
  width: 500px;
  margin-left: -250px;
  height: 2px;
  left: 50%;
  background: #5d0ae2;
}

@media only screen and (max-width: 445px) and (min-width: 320px) {
  #Contact .titlebox h2:before {
    width: 300px;
    margin-left: -150px;
  }
}

/* SECTION */

/* SCROLLBAR */

body::-webkit-scrollbar {
  width: 0.2rem;
}

body::-webkit-scrollbar-track {
  background: rgb(27, 27, 27);
}

body::-webkit-scrollbar-thumb {
  background: rgba(249, 176, 51, 0.233);
}

/* SCROLLBAR */

@keyframes change {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* Media */

@media only screen and (max-width: 530px) and (min-width: 330px) {
  .logo {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 530px) and (min-width: 330px) {
  #Price_list .titlebox h2 {
    margin-top: 15%;
  }
}

@media only screen and (max-width: 860px) and (min-width: 330px) {
  #Home .background {
    background-size: 60vh;
  }
}

@media only screen and (max-height: 820px) and (min-height: 330px) {
  #Home .background {
    background-size: 60vh;
  }
}

@media only screen and (max-width: 520px) and (min-width: 330px) {
  #Home .background {
    background-size: 40vh;
    height: 80% !important;
  }
}

@media only screen and (max-width: 865px) and (min-width: 330px) {
  #Price_list .titlebox h2 {
    margin-right: 0%;
  }
}

/* Media */

/* Scrolltotop */

#scrolltotop {
  position: fixed;
  right: 25px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.25);
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  z-index: 9999;
  background: rgba(116, 116, 116, 0.61);
}

#scrolltotop:active {
  background: white;
}

.arrow {
  width: 40px;
  height: 40px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

/* Scrolltotop */

/* Table */

#Price_list table {
  position: relative;
  left: 40%;
  top: 20%;
  transform: translate(-50%, -50%);
  border-collapse: collapse;
  width: 800px;
  height: 200px;
  border: 1px solid #575757;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3), -1px -1px 8px rgba(0, 0, 0, 0.3);
  margin-top: 10%;
  background-color: #161b1bd5;
}

#Price_list tr {
  transition: all 0.2s ease-in;
  cursor: pointer;
}

#Price_list tr:hover {
  background-color: transparent;
  transform: scale(1.02);
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3), -1px -1px 8px rgba(0, 0, 0, 0.3);
}

#Price_list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #575757;
}

#Price_list .deck {
  position: relative;
  color: rgb(255, 255, 255);
  line-height: 1.6;
  padding-bottom: 15px;
  margin-top: 30vh;
}

@media (min-width: 870px) {
  .deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: 170px;
  }
}

@media (min-width: 576px) {
  .cenik {
    flex: 1 0 0%;
  }
}

@media only screen and (max-width: 865px) and (min-width: 320px) {
  #Price_list table {
    width: 90% !important;
  }
}

@media only screen and (max-width: 875px) and (min-width: 320px) {
  #Price_list table {
    top: 30% !important;
  }
}

@media only screen and (max-width: 615px) and (min-width: 320px) {
  #Price_list table {
    margin-top: 30% !important;
  }
}

@media only screen and (max-width: 560px) and (min-width: 320px) {
  #Price_list table {
    margin-top: 35% !important;
  }
}

@media only screen and (max-width: 420px) and (min-width: 320px) {
  #Price_list table {
    margin-top: 45% !important;
  }
}

@media only screen and (max-width: 1140px) and (min-width: 975px) {
  #Price_list table {
    left: 43%;
  }
}

@media only screen and (max-width: 1750px) and (min-width: 1140px) {
  #Price_list table {
    left: 45% !important;
  }

  #Price_list .titlebox h2 {
    margin-right: 12vh;
  }
}

@media only screen and (max-width: 950px) and (min-width: 320px) {
  #Price_list table {
    left: 50% !important;
  }
  #Price_list .deck {
    margin-top: 20vh;
  }
}

@media only screen and (max-width: 955px) and (min-width: 866px) {
  #Price_list table {
    left: 35% !important;
  }
}

@media only screen and (max-width: 869px) and (min-width: 330px) {
  #Price_list table {
    margin-top: 25%;
  }
}

@media only screen and (max-width: 520px) and (min-width: 330px) {
  #Price_list table {
    margin-top: 50% !important;
  }
}

/* Table */

/* Map 

 /* Map */

/* Footer */

footer {
  position: relative;
  bottom: 0;
}

@media (max-height: 800px) {
  footer {
    position: static;
  }
}

.footerdistributed {
  background-color: rgb(27, 27, 27);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 50px 50px 60px 50px;
}

.footerdistributed .footerleft,
.footerdistributed .footercenter,
.footerdistributed .footerright {
  display: inline-block;
  vertical-align: top;
}

.footerdistributed .footerleft {
  width: 30%;
}

.footerdistributed h3 {
  color: #ffff;
  margin: 0;
  text-transform: uppercase;
}

.footerdistributed .footerlinks {
  color: #fff;
  margin: 20px 0 12px;
}

.footerdistributed .footerlinks a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footerdistributed .footercompanyname {
  color: #8f9296;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footerdistributed .footercenter {
  width: 35%;
}

.footerdistributed .footercenter i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 17px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  margin: 8px 15px;
  vertical-align: middle;
}

.footerdistributed .footercenter i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

@media only screen and (max-width: 380px) and (min-width: 330px) {
  .footerdistributed .footercenter i.fa-envelope {
    display: none;
  }
}

.footerdistributed .footercenter p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footerdistributed .footercenter p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footerdistributed .footercenter p a {
  color: #ffffff;
  text-decoration: none;
}

.footerdistributed .footerright {
  width: 30%;
}

.footerdistributed .footercompanyabout {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footerdistributed .footercompanyabout span {
  display: block;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footerdistributed .footericons {
  margin-top: 25px;
}

.footerdistributed .footericons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}

.footerdistributed .footericons a:hover {
  background-color: #341fec;
}

.footerlinks a:hover {
  color: #341fec;
}

@media (max-width: 880px) {
  .footerdistributed .footerleft,
  .footerdistributed .footercenter,
  .footerdistributed .footerright {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footerdistributed .footercenter i {
    margin-left: 0;
  }
}
/* Footer */

/* Soc. Ico */

.tableico {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.tableico tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.tableico td {
  display: table-cell;
  vertical-align: inherit;
  padding: 15px;
  height: 80px;
  width: 80px;
}

.socialicons {
  position: absolute;
  left: 4%;
  top: 91%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.tableico img {
  border: none;
  border-radius: 50%;
  transition: 0.5s ease;
  height: 51px;
  width: 51px;
}

.tableico img:hover {
  transform: scale(1.25);
}

/* COOKIES */

@media only screen and (max-width: 1355px) {
  .cookie-container {
    width: 95% !important;
  }
}

@media only screen and (max-width: 630px) {
  .cookie-container {
    width: 90% !important;
  }
}

.cookie-container {
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(20, 20, 20, 0.74);
  color: white;
  padding: 0 32px;
  transition: 400ms;
  margin: 20px;
  border-radius: 3px;
  z-index: 9999 !important;
  width: 30rem;
  word-break: break-all;
}

.cookie-container p {
  margin-top: 20px;
}

.cookie-btn {
  background: rgb(148, 41, 9);
  border: 0;
  color: white;
  padding: 12px 48px;
  font-size: 18px;
  margin-bottom: 8px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  float: right;
}

/* COOKIES */

/*mapa*/
.mapa {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 55px;
  filter: drop-shadow(15px 15px 15px rgb(8, 129, 18)) invert(500%);
  opacity: 0.7;
}

.mapa:hover {
  filter: none;
  transition: 1.5s;
  opacity: 1;
}
/*mapa*/

/* Video */

#Video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: fit-content;
  z-index: 1;
  filter: brightness(0.3);
  overflow: hidden !important;
  object-fit: cover !important;
}
